import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Explore from "../components/explore"

import yepmed from "../images/yepmed.png"
import crossdev from "../images/crossdev.png"
import restartmed from "../images/restartmed.png"

const CoopPage = () => (
  <div id="indexPage">
    <Header/>
    <div className="content-pad">
      <h2>Sinergie con Progetti ENI CBC MED</h2>
      <p>
        Uno dei principi fondamentali su cui si fonda il progetto FISH MED NET è la creazione di un
        ambiente cooperativo tra i vari stakeholder, fornendo la necessaria visibilità degli
        stakeholder coinvolti a terzi. Tutti questi sforzi mirano a un mondo migliore in cui vivere, e
        allo stesso modo il progetto cerca sinergie con altri progetti ENI CBC MED che condividono
        obiettivi simili. In questa pagina è possibile visualizzare i diversi progetti che hanno sinergie
        con FISH MED NET e saperne di più su quali UN SDGs sono condivisi con ciascun
        progetto.
      </p>

      <div className="coop">
        <a href="https://www.enicbcmed.eu/projects/yep-med"><img src={yepmed}/></a>
        <p>
          Il progetto <strong>Youth Employment in the Ports of the Mediterranean</strong>, mira ad aumentare il coinvolgimento dei giovani migliorando il livello della logistica portuale, aumentare le opportunità di occupazione locale con tirocini e rafforzare il ruolo delle PMI che operano negli ecosistemi portuali. Il progetto mira a creare un partenariato collaborativo nazionale e transnazionale tra le parti interessate coinvolte nella logistica portuale in tutto il bacino del Mediterraneo.
        </p>
      </div>
      <hr/>

      <div className="coop">
        <a href="https://www.hiddenmediterranean.net/en/"><img src={crossdev}/></a>
        <p>
          <strong>CROSSDEV PROJECT</strong> è un progetto costruito per elevare le destinazioni turistiche meno conosciute attraverso la cultura socio-economica, locale e il loro patrimonio in tutto il Mediterraneo. Il progetto ha sviluppato una collaborazione tra paesi migliorando le politiche e le pratiche del turismo sostenibile con l'obiettivo di migliorare il sostentamento delle parti interessate.
        </p>
      </div>
      <hr/>

      <div className="coop">
        <a href="https://medsustainabletourism.net/"><img src={restartmed}/></a>
        <p>
          Il progetto <strong>RESTART MED!</strong> contribuisce allo sviluppo economico e sociale del Mediterraneo, rivitalizzando l'economia del turismo sostenibile dopo la pandemia attraverso 4 strategie chiave: diversificazione, innovazione, destagionalizzazione e sviluppo dell'economia locale.<br/>
          Il progetto mira a rimodellare la domanda turistica, sostenendo le autorità nello sviluppo di politiche di turismo sostenibile e incoraggiando la cooperazione tra le parti interessate del turismo.
        </p>
      </div>

      <h2>Esplora tra le ultime proposte</h2>
    </div>
    <Explore slug="index"/>

    <Footer/>
  </div>
)

export default CoopPage
